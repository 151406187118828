<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('订单管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('订单编号')" v-model="query.code" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('店长名')" v-model="query.shopOwner" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('国内物流单号')" v-model="query.innerExpCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('转寄单号')" v-model="query.backCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('商品名')" v-model="query.name" size="small">
          <i slot="prefix" class="el-input__icon el-icon-s-goods"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('创建开始日期')"  value-format="yyyy-MM-dd"
            :end-placeholder="msg('创建结束日期')"
            size="small"
          >
        </el-date-picker>
    </el-col>
  </el-row>

  <vxe-toolbar id="toolbar14" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <el-button type="primary" size="mini" @click="add()" v-if="hasPermission('order:create')">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('添加')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="edit()" v-if="hasPermission('order:update')">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('编辑')}}</span>
          </transition>
        </el-button>
        <el-button type="danger" size="mini" @click="deleted()" v-if="hasPermission('order:delete')">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="uploadCode()" v-if="hasPermission('order:codeImport')">
          <i class="el-icon-upload2" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导入货架号')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="addShelfCode()" v-if="hasPermission('order:updateShelfCode')">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('添加货架号')}}</span>
          </transition>
        </el-button>
        <!-- <el-button type="success" size="mini" @click="resend()" v-if="hasPermission('express:resend')">
          <i class="el-icon-refresh" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('退件转寄')}}</span>
          </transition>
        </el-button> -->
      </el-button-group>
    </template>
    <template v-slot:tools>
      <el-button-group>
        <el-button type="success" @click="getOrders(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
         <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: msg('订单信息下载'),sheetName: 'Sheet1' })" v-if="hasPermission('express:download')">
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出')}}</span>
          </transition>
        </el-button>
      </el-button-group>
     </template>
  </vxe-toolbar>
  <div class="table-wrap">
    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="inner" size="small"  stripe resizable  :data="orders" 
      show-overflow 
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    >
      <vxe-table-column type="checkbox" width="40" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column field="id" :title="msg('ID')"  min-width="90" :fixed="isMinScreen ? null : 'left'" 
        sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
      ></vxe-table-column>
      <vxe-table-column field="created" :title="msg('建单时间')" min-width="120"
         sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      >
      </vxe-table-column>
      <vxe-table-column field="code" :title="msg('物流信息编号')" min-width="150" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
      </vxe-table-column>
      <vxe-table-column field="name" :title="msg('商品名')" min-width="120" :show-overflow="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
        <template v-slot="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.name">
             <span>{{row.name}}</span>
          </el-tooltip>
        </template>
      </vxe-table-column>
      <vxe-table-column field="spec" :title="msg('规格')" min-width="150" :show-overflow="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
        <template v-slot="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.spec">
             <span>{{row.spec}}</span>
          </el-tooltip>
        </template>
      </vxe-table-column>
      <vxe-table-column field="qty" :title="msg('件数')" width="100" 
        sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
      >
      </vxe-table-column>
      <vxe-table-column field="price" :title="msg('价格')" width="100" 
        sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
      >
      </vxe-table-column>
      <vxe-table-column field="shopOwner" :title="msg('店铺名')" min-width="120" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      ></vxe-table-column>
      <vxe-table-column field="innerExpCode" :title="msg('国内物流快递单号')" min-width="180" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
      </vxe-table-column>
      <vxe-table-column field="resendCode" :title="msg('退件转寄单号')" min-width="160" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}" :show-overflow="true"
      >
        <template v-slot="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.resendCode">
             <span>{{row.resendCode}}</span>
          </el-tooltip>
        </template>
      </vxe-table-column>:filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}" 
      <vxe-table-column field="storeShelfCode" :title="msg('仓库货架号')" min-width="130" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      >
        <template v-slot="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.storeShelfCode">
             <span>{{row.storeShelfCode}}</span>
          </el-tooltip>
        </template>
      </vxe-table-column>
      <vxe-table-column field="createrName" :title="msg('创建人')" min-width="130" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      >
      </vxe-table-column>
      <vxe-table-column field="remark" :title="msg('备注')" min-width="130" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}" :show-overflow="true"
      >
        <template v-slot="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.remark">
             <span>{{row.remark}}</span>
          </el-tooltip>
        </template>
      </vxe-table-column>
    </vxe-table>
    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

  <el-dialog :title="dglogTitle" :visible.sync="dialogTableVisible" :fullscreen="isMinScreen" @opened="() => {this.$refs.orderEdit.initForm(this.currOrder.productPicId);}">
    <order-edit :id="currId" :order="currOrder" v-on:closeDg="closeEditDialog" ref="orderEdit"/>
  </el-dialog>

  <el-dialog :title="msg('上传货架号')" :visible.sync="codeImportVisible" :fullscreen="isMinScreen"  width="600px" @opened="codeImport()" >
    <order-code-import ref="shelfCodeImport"/>
  </el-dialog> 

  <el-dialog :title="msg('添加货架号')" :visible.sync="dialogCodeVisible" :fullscreen="isMinScreen" @opened="handleShelfOpen" width="600px" :destroy-on-close="true">
    <order-shelf-code-edit v-on:closeDg="closeCodeDialog" ref="shelfEdit"/>
  </el-dialog>

  <el-dialog title="退件转寄" :visible.sync="dialogBackEditVisible" :fullscreen="isMinScreen" width="600px">
    <order-back-edit :orders="backOrders" v-on:closeDg="closeBackEditDialog" ref="expBackEdit" :has-view="false"/>
  </el-dialog>

</div>
</template> 

<script>
// @ is an alias to /src
import OrderEdit from "./OrderEdit.vue";
import OrderCodeImport from "./OrderCodeImport.vue";
import OrderShelfCodeEdit from "./OrderShelfCodeEdit.vue";
import OrderBackEdit from './OrderBackEdit.vue';

export default {
  name: 'Order',
  components: {
    OrderEdit: OrderEdit,
    OrderCodeImport:OrderCodeImport,
    OrderShelfCodeEdit:OrderShelfCodeEdit,
    OrderBackEdit:OrderBackEdit
  },
  mounted:function(){
    var _this = this;
    var user = JSON.parse(window.localStorage.getItem("user"));
    this.userName = user.username;
    this.getOrders();
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          code: "",
          shopOwner: "",
          Date: [], 
        },
        currentPage: 1,   //分页的当前页
        orders: [],   //表格数据列表
        dglogTitle: this.msg('编辑订单'),
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        dialogTableVisible: false, //编辑详情的隐藏与显示,
        columns: [],  //字段是否显示
        currOrder:null,
        codeImportVisible:false,
        dialogCodeVisible:false,
        userName:null,
        backOrders:[],
        dialogBackEditVisible:false,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    codeImport(){
        this.$nextTick(() => {
        this.$refs.shelfCodeImport.initForm();
      });
    },
    handleShelfOpen(){
      this.$nextTick(() => {
        this.$refs.shelfEdit.initForm(this.isAdd)
      });
    },
    closeCodeDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getOrders();
      }
      this.dialogCodeVisible = false;
    },
    addShelfCode(){
        this.isAdd = true;
        this.dialogCodeVisible = true;
    },
    uploadCode(){
      this.codeImportVisible = true;
    },
    getOrders: function(start){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);
      if(start){
        param.start = 0;
        this.currentPage = 1;
      }

      this.axios.post(this.$kit.api.orderList, param).then(function (result) {
        if(result.status){
          if(_this.orders.length>0)
            _this.orders.splice(0, _this.orders.length);
          
          _this.total = result.data.recordsTotal;
          var orderDatas = result.data.data;

          for(var i=0; i<orderDatas.length; i++){
            var item = orderDatas[i];

            _this.orders.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getOrders();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getOrders();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getOrders();
    },
    add(){  //添加
      this.currId = null;
      this.currOrder = {
        code: null,
        name: null,
        spec: null,
        qty: 1,
        price:0,
        shopOwner: null,
        remark: null
      };
      this.dglogTitle = this.msg("添加新订单");

      this.dialogTableVisible = true;
    },
    edit(){  //修改
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      if(selectes.length>1){
        this.$message(this.msg('只能编辑一条数据'));
        return;
      }

      if(this.userName != "admin"){
          if(selectes[0].status >= 2){
              this.$message(this.msg('已发货订单不能修改'));
              return;
          }
      }

      _this.currOrder = selectes[0];
      delete _this.currOrder.created;
      _this.currId = _this.currOrder.id;
      _this.dglogTitle = this.msg("编辑订单");
      _this.dialogTableVisible = true;
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getOrders();
      }
      this.dialogTableVisible = false;
    },
    deleted(){  //删除
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var ids = selectes.map( o => o.id);
      
      var _this = this;

      this.$confirm(this.msg('确定要删除该订单吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.post(_this.$kit.api.orderDelete, ids)
            .then(function(result){
              _this.$message(result.msg || _this.msg('删除成功'));
              _this.getOrders();
          });
        });
    },
    resend(){ //转出重寄
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var _this = this;

      //将选中的数据封装成list,传到弹出的窗口
      for(var i = 0;i < selectes.length;i++){
          var express = {
              id:selectes[i].id,
              orderCode:selectes[i].code,
          }
          this.backOrders.push(express);
      }

      this.dialogBackEditVisible = true;

    },
    closeBackEditDialog(type){  //编辑弹窗关闭后的回调事件
      this.getOrders();
      this.backOrders = [],
      this.dialogBackEditVisible = false;
    },
  }
}
</script>

<style class="scss" scoped>


</style>
