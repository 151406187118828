<template>
<el-form ref="form" :model="order" label-width="80px">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('商品名')">
                <el-input v-model="order.name"></el-input>
                <el-checkbox v-model="order.updateAllName">同时更新所有同名物流信息</el-checkbox>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('订单编号')">
                <el-input v-model="order.code" ></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12" >
            <el-form-item :label="msg('国内快递单号')" label-width="120px">
                <el-input v-model="order.innerExpCode"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" >
            <el-upload class="upload-demo" :action="uploadUrl"
                style="margin-left:10px;"
                :on-success="handleSuccess"
                :file-list="fileList"
                :multiple="false"
                :with-credentials="true"
                :limit="1"
                list-type="picture">
                <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('价格')">
                <el-input v-model="order.price" type="text"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('规格')">
                <el-input v-model="order.spec" type="text"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('件数')">
                <el-input v-model="order.qty" type="text"></el-input>
            </el-form-item>
        </el-col>
       <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('店长名')">
                <el-input v-model="order.shopOwner" type="text"></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('货架号')">
                <el-input v-model="order.storeShelfCode" type="text"></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <el-form-item :label="msg('备注')">
        <el-input type="textarea" v-model="order.remark" ></el-input>
    </el-form-item>
    
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'OrderEdit',
  data(){
    return{
       uploadUrl: this.$kit.api.upload+"?type=product",
       imgView: this.$kit.api.imgView,
       fileList: []
    }
  }, 
  computed:{
    mode: {
        get(){
            return this.id==null;
        }
    },
    isEdit: {
        get(){
            return this.id==null;
        }
    }
  },
  props:{
    id:null,
    order:Object,
  },
  mounted: function(){
    var _this = this;
  },
  methods:{
    initForm(picId){
        this.fileList = [];
        if(picId){
            this.fileList.push({url: this.imgView+picId, name: picId});
        }
    },
    handleSuccess(res, file, fileList){
        if(res.status){
            this.order.productPicId = res.data;
            this.$message("上传成功");
        }else{
            this.$message("上传失败");
        }
    },
    onSubmit(){
        var url = null;
        if(this.id==null){
            url = this.$kit.api.orderCreate;
        }else{
            url = this.$kit.api.orderUpdate;
        }

        var _this = this;

        this.$axios.post(url, this.order)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
               
            });
    }
  }
}
</script>

<style class="scss" scoped>


</style>
