<template>
<div>
    <el-form ref="form" label-width="140px">
        <el-form-item :label="msg('国内物流快递单号')" v-if="isAdd && !openAutoSave">
            <el-input v-model="innerCode"></el-input>
        </el-form-item>
        <el-form-item :label="msg('国内物流快递单号')" v-if="isAdd && openAutoSave" @keyup.native="autoSave">
            <el-input v-model="innerCode"></el-input>
        </el-form-item>
        <el-form-item :label="msg('货架号')">
            <el-input v-model="code"></el-input>
        </el-form-item>
        <el-form-item :label="msg('自动保存')">
            <el-switch v-model="openAutoSave " v-if="isAdd" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </el-form-item>
        <div style="text-align:center;">
            <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
            <el-button  size="mini" @click="closeDialog()">{{msg('取消')}}</el-button>
        </div>
    </el-form> 
    <div style="display:inline-block; width:1px; height:1px; overflow:hidden;">
        <audio src="/media/1.wav" controls="controls" preload ref="wav"></audio>
    </div>
</div>

</template>

<script>
export default {
  name: 'ExpBackShelfEdit',
  data(){
    return{
       code: null,
       innerCode: null,
       prevPageCode: null,
       isAdd: false,
       openAutoSave: false,
       inputing: false,
    }
    
  }, 
  computed:{
  },
  props:{
  },
  mounted: function(){
        var _this = this;
       
  },
  methods:{
    initForm(hasAdd){
        this.isAdd = hasAdd;
    },
    onSubmit(){
        var url = this.$kit.api.orderUpdateShelfCode;

        if(!this.code){
            this.$message("货架号不能为空");
        }

        if(!this.innerCode){
            this.$message("国内快递物流单号不能为空");
        }
        
        var ord = {
            innerExpCode: this.innerCode,
            storeShelfCode: this.code
        }

        var _this = this;
        this.$axios.post(url, ord)
            .then(function(result){
                if(result.status){
                    _this.$message(result.msg ||  _this.msg('更新成功'))
                }else{
                   
                    _this.$message(result.msg || _this.msg('更新失败'));
                }
               
            }).catch(function (error) {
                console.error(error);
                this.clearInputer();
            });

    },
    clearInputer(){
        this.innerCode = null;
        this.code = null;
    },
    closeDialog(type){
        if(!this.isAdd) 
            this.code = null;
        this.$emit("closeDg", type);
    },
    autoSave(){
        
        if(this.inputing == false){
            this.inputing = true;
            var _this = this;
            var timer = setInterval(() => {
                
                if(_this.prevPageCode == _this.innerCode){
                    clearInterval(timer);
                    _this.doAutoSave();
                    _this.inputing = false;

                }else{
                    _this.prevPageCode = _this.innerCode;
                }

            }, 500);
        }
    },
    doAutoSave(){

        if(!this.innerCode){
            this.$message("国内物流快递单号不能为空");
        }

        if(!this.code){
            this.$message("货架号不能为空");
        }

        var expShelf = {
            storeShelfCode: this.code,
            innerExpCode: this.innerCode
        }

        this.$axios.post(this.$kit.api.orderUpdateShelfCode, expShelf)
            .then((result)=>{
                this.$message(result.msg);
                this.innerCode = null;
                this.prevPageCode = null;

                if(!result.status){
                    this.$refs.wav.play(); 
                }

            }).catch(function (error) {
                console.error(error);
                this.innerCode = null;
                this.prevPageCode = null;
            });


    }
  },
  
}
</script>

<style class="scss" scoped>


</style>
