<template>
<el-form ref="form" label-width="80px">
    <el-table :data="orders" style="width: 100%" size="mini" ref="dataTable" max-height="400" stripe>
        <el-table-column prop="orderCode" :label="msg('货单号')" min-width="80" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="" :label="msg('转寄单号')" min-width="150" :show-overflow-tooltip="true">
            <template slot-scope="scope">
                <el-form-item label-width="150" :prop="'expBacks.' + scope.$index + '.resendCode'" >
                    <!-- <el-input v-model="scope.row.resendCode" placeholder="请输入转寄单号"
                        size="mini"></el-input> -->
                    <el-select
                        v-model="scope.row.resendCodes"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="输入完成按回车键">
                    </el-select>
                </el-form-item>
            </template>
        </el-table-column>
    </el-table>
    <div style="text-align:center;padding-top: 20px;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'ExpPkgEdit',
  data(){
    return{
       storePkg:null,
    }
    
  }, 
  computed:{
  },
  props:{
      orders:Array,
  },
  mounted: function(){
        var _this = this;
  },
  methods:{
    onSubmit(){

        var _this = this;
        
        if(this.orders){
            for(var i=0;i<this.orders.length;i++){
                var exp = this.orders[i];
                if(!exp.resendCodes){
                    this.$message(this.msg('转寄单号不能为空'));
                    return;
                }
            }
        }

        this.$axios.post(_this.$kit.api.expResend,this.orders)
            .then(function(result){
                _this.$message(result.msg);
                
            }).catch(function (error) {
                console.error(error);
            });

    }
  }
}
</script>

<style class="scss" scoped>
.el-form-item{
    margin-bottom: 0;
}

</style>
